import Home from './pages/Home'

function App() {
  console.log('cicd test #1');
  
  return (
    <Home/>
  );
}

export default App;