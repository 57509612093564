import React from 'react'
import styled from 'styled-components';
import introStyleAtom from '../../common/introStyleAtom';
import images from '../../common/images';

const Intro5 = () => {
    const {TagBox} = introStyleAtom();

  return (
    <Wrapper>
          <Flex>
            <Left>
                <InfoBox>
                    <MidTitle>
                      기록 확인
                    </MidTitle>
                    <Line/>
                    <BotTitle>
                      기존에는 보기 어려웠던 기록을 대회 기록뿐만 아니라 경기 기록까지 상세하게 확인할 수 있는 이점!
                    </BotTitle>
                </InfoBox>
                <ImgBox>
                  <SImg src={images.intro5.phone1} alt=''/>
                  <TagBox className='pc' style={{backgroundColor:'#FFF', color:'#232323'}}>04</TagBox>
                </ImgBox>
            </Left>
          </Flex>

          <Flex2>
            <Right>
                <InfoBox>
                    <MidTitle>
                      체력 및 심리 측정
                    </MidTitle>
                    <Line/>
                    <BotTitleRight>
                      체력 측정한 기록을 상세하게 확인할 수 있고 WSTI로 심리까지 측정 가능!
                    </BotTitleRight>
                </InfoBox>
                <ImgBox>
                  <SImg src={images.intro5.phone2} alt=''/>
                  <TagBox className='mobile' style={{backgroundColor:'#FFF', color:'#232323'}}>04</TagBox>
                </ImgBox>
            </Right>
          </Flex2> 
    </Wrapper>
  )
}

export default Intro5

const Wrapper = styled.div`
width: 100%;
/* height: 775px;  */
height: 100vh;
display: flex;

@media (max-width: 800px) { 
  flex-direction: column;
  height: 100%;

  .pc {
    display: none;
  }
}

@media (min-width: 800px) { 
  .mobile {
    display: none;
  }
}
`

const Flex = styled.div`
flex: 1; 
background-color: #2E5CB0;
display: flex;
justify-content: flex-end; 
height: 100%;
@media (max-width: 800px) { 
  justify-content: center;
}
`

const Flex2 = styled.div`
flex: 1;
display: flex;
background-color: #3368CA;
@media (max-width: 800px) { 
  justify-content: center;
}
`

const Container = styled.div`
width: 790px;
height: 100%;
overflow: hidden;
display: flex;
flex-direction: column;
`

const Left = styled(Container)`
padding-right: 60px;
padding-left: 70px;
padding-top: 69px;
@media (max-width: 800px) { 
  width: 375px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0;
}
`

const Right = styled(Container)`
padding-right: 70px;
padding-left: 60px;
padding-top: 69px;

text-align: right;
align-items: end;
justify-content: end;
@media (max-width: 800px) { 
  width: 375px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 14px;
  text-align: left;
  align-items: center;
  justify-content: center;
}
`

const InfoBox = styled.div`
/* margin-top: 57px; */
margin-top: 16vh;
@media (max-width: 800px) { 
  margin-top: 30px;
  margin-bottom: 25px;
  margin-left: 12px;
}
`

const MidTitle = styled.div`
color: #FFF; 
font-size: 55px; 
font-weight: 800;
line-height: 82.459px; 
@media (max-width: 800px) { 
  font-size: 28px;
  line-height: 28px;
}
`

const Line = styled.div`
width: 100%;
border-top: 1px solid #ffffff;
color: white;
margin-top: 24px;
margin-bottom: 34px;
@media (max-width: 800px) { 
  width: calc(100% - 24px);
  margin: 8px 0;
}
`

const BotTitle = styled.div`
width: 536px;
color: #FFF; 
font-size: 24px; 
font-weight: 500;
line-height: 34px;  
@media (max-width: 800px) { 
  font-size: 12px;
  line-height: 17px;
  width: 283px;
  margin-left: 0;
}
`

const BotTitleRight = styled.div`
width: 445px;
color: #FFF; 
font-size: 24px; 
font-weight: 500;
line-height: 34px;  
margin-left: auto;
@media (max-width: 800px) { 
  font-size: 12px;
  line-height: 17px;
  width: 283px;
  margin-left: 0;
}
`

const ImgBox = styled.div`
width: 633px;
position: relative;
margin-top: auto;

@media (max-width: 800px) { 
  width: 100%;
}
`

const SImg = styled.img`
width: 633px;
@media (max-width: 800px) { 
  min-width: 290px;
  width: 100%;
  padding: 0 12px;
}
`
