import React from 'react'
import styled from 'styled-components'
import introStyleAtom from '../../common/introStyleAtom'
import images from '../../common/images';

const Intro2 = () => {
    const {Flex, Flex2, TagBox} = introStyleAtom();

  return (
    <Background>
        <Wrapper>
            <Container>
                <Flex>
                    <InfoBox>
                        <TopTitle>더욱 더 새로워진 WEDID 2.0</TopTitle>
                        <MidTitle>나의 스포츠 활동을 기록하고 싶다면?</MidTitle>
                        <MidLine/>
                        <BotTitle>WEDID에서스포츠 활동 기록을 카드로 증명할 수 있어요!</BotTitle>
                        
                    </InfoBox>
                    <TagBox style={{backgroundColor:'#FF8667', color:'white'}}>02</TagBox>
                </Flex>
                <Flex2>
                    
                </Flex2>
            </Container>
        </Wrapper>
    </Background>
  )
}

export default Intro2

const Background = styled.div`
width: 100%;
/* height: 100%; */
height: 100vh;
background-color: #FFF2EE;
@media (max-width: 800px) {  
    height: 100%;
}
`

const Wrapper = styled.div`
width: 100%;
/* height: 675px; */
height: 100%;
background-color: #FFF2EE;
position: relative;
display: flex;
justify-content: center;
background-image: url('${images.intro2.background}');
background-repeat: no-repeat;
background-position: bottom;
@media (max-width: 800px) {  
    background-image: url('${images.intro2.background_m}');
    background-color: #FFF2EE;
    width: 375px;
    height: 316px;
    margin: auto;
    background-position: center;  
    background-size: cover;
}
`

const Container = styled.div`
display: flex;
width: 1580px;
padding-left: 70px;
padding-right: 70px;
height: 100%;
@media (max-width: 800px) { 
  width: 375px;
  padding-left: 25px;
  padding-right: 25px;
}
`

const InfoBox = styled.div`
margin-left: 31px;
@media (max-width: 800px) {  
    margin-left: 12px;
}
`

const TopTitle = styled.div`
color: #FF8667;
font-size: 32px;
font-weight: 700;
line-height: 35px;
margin-top: 32px;
@media (max-width: 800px) {  
    font-size: 14px; 
    margin-top: 6px;
}
`

const MidTitle = styled.div`
max-width: 554px;
height: 192px;
color: #FF8667;
font-family: NanumSquare Neo OTF;
font-size: 70px;
font-weight: 800;
line-height: 96px; 
letter-spacing: -1.4px;
margin-top: 12vh;
@media (max-width: 800px) { 
    font-size: 24px;
    max-width: 190px; 
    height: 60px;
    line-height: 30px; 
    margin-top: 15px;
}
`

const MidLine = styled.div`
width: 600px; 
border-top: 1px solid #FF8667; 
margin-top: 36px;
margin-bottom: 36px;
@media (max-width: 800px) {  
    width: 291px;
    margin-top: 8px;
    margin-bottom: 8px;
}

`

const BotTitle = styled.div`
width: 325px;
height: 68px;
color: #FF8667;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 34px;

@media (max-width: 800px) { 
    width: 163px;
    height: 34px;
    font-size: 12px; 
    line-height: 17px;  
}




`