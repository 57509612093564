import React from 'react'
import styled from 'styled-components'
import images from '../../common/images'
import introStyleAtom from '../../common/introStyleAtom'


const Intro1 = () => {
  const {Container, Flex, Flex2, TagBox} = introStyleAtom();
  

  return (
    <Wrapper>
      <BackImg src={images.intro1.background} alt=''/>
      <LogoImg src={images.intro1.logo} alt=''/>
      <Container>
        <Flex>
          <TagBox>01</TagBox>
        </Flex>
        <Flex2>
        </Flex2>
      </Container>
    </Wrapper>
  )
}

export default Intro1

const Wrapper = styled.div`
width: 100%;
/* height: 844px; */
height: 100vh;
position: relative;
display: flex;
justify-content: center;
background-color: #FFF2EE;
@media (max-width: 800px) { 
  height: 261px;
}
`

const BackImg = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
position: absolute; 
z-index: 1;
`

const LogoImg = styled.img`
width: 600px;
height: 240px;
z-index: 2;
position: absolute;
/* margin-top: 275px; */
margin-top: 33vh;

@media (max-width: 800px) { 
  width: 211px;
  height: 84px;
  margin-top: 82px;
}
`