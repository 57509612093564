import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Intro1 from '../components/home/Intro1'
import Intro7 from '../components/home/Intro7'
import Intro2 from '../components/home/Intro2'
import Intro3 from '../components/home/Intro3'
import Intro4 from '../components/home/Intro4'
import Intro5 from '../components/home/Intro5'
import Intro6 from '../components/home/Intro6'

const Home = () => {
  const outerDivRef = useRef();
  
  useEffect(() => {
    const wheelHandler = (e) => {
      e.preventDefault();
      const { deltaY } = e;
      const scrollTop = window.scrollY;
      const pageHeight = window.innerHeight; 
      const page = (scrollTop / pageHeight) + 1;
      console.log(pageHeight,'2');
      console.log(deltaY,'3');
      console.log(scrollTop,'4');
      console.log(page,'띠용?');

      if (deltaY > 0) {
        // scroll down
        if (scrollTop >= 0 && scrollTop < pageHeight) {
          window.scrollTo({ 
            top: pageHeight ,
            behavior: 'smooth' 
          });

        } else if (scrollTop >= pageHeight && scrollTop < pageHeight * 2) {
          window.scrollTo({
            top: pageHeight * 2,
            left: 0,
            behavior: "smooth",
          });
          
        } else {
          window.scrollTo({
            top: pageHeight * page,
            left: 0,
            behavior: "smooth",
          });
        }
      } else {
        // scroll up
        if (scrollTop >= 0 && scrollTop < pageHeight) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        // } else if (page > 5) {
        //   window.scrollTo({
        //     top: 5160,
        //     left: 0,
        //     behavior: "smooth",
        //   });
          
        } else {
          window.scrollTo({
            // top: (page-2) * pageHeight,
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    };
    const outerDivRefCurrent = outerDivRef.current;
    outerDivRefCurrent.addEventListener("wheel", wheelHandler);
    return () => {
      outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
    };
  }, []);

  return (
    <Wrapper ref={outerDivRef} className="outer">
        <Intro1/>
        <Intro2/>
        <Intro3/>
        <Intro4/>
        <Intro5/>
        <Intro6/>
        <Intro7/>
        <Footer/>
    </Wrapper>
  )
}

export default Home

const Wrapper = styled.div`
min-width: 1780px;
@media (max-width: 800px) {
  min-width: 375px;
}
`