import React from 'react'
import styled from 'styled-components'
import images from '../../common/images'
import { goHere } from '../../common/util'
import Footer from '../Footer'

function Intro7() {
    

  return (
    <>
    <Wrapper>
        <Container>
            <TopTitle>WEDID APP 다운로드</TopTitle>
            <LogoBox>
                <img src={images.intro7.appLogo} alt='WEDID!'/>
            </LogoBox>
            <ButtonBox>
                <span onClick={()=>{goHere('https://apps.apple.com/us/app/wedid/id1597077868')}}>
                    <img src={images.intro7.appStore} alt='WEDID!'/>
                </span>
                <span onClick={()=>{goHere('https://play.google.com/store/apps/details?id=com.projectwith.withapp_did&pli=1')}}>
                    <img src={images.intro7.playStore} alt='WEDID!'/>
                </span>
            </ButtonBox>
        </Container>
    </Wrapper>
    </>
  )
}

export default Intro7

const Wrapper = styled.div`
width: 100%;
/* height: 817px; */
height: 100vh;
background-color: #232323;
display: flex;
justify-content: center;
@media (max-width: 800px) {  
    height: 306px;
}
`;

const Container = styled.div` 
padding-bottom: 258px;
width: 1580px;
padding-left: 70px;
padding-right: 70px;
@media (max-width: 800px) {
  width: 375px;
  padding: 0 25px;
}
`;

const TopTitle = styled.div`
color: #FFF; 
font-size: 32px; 
font-weight: 700;
line-height: 35.534px; 
margin-top: 32px;
margin-left: 31px;
@media (max-width: 800px) {  
  font-size: 14px;
  margin-left: 12px;
  margin-top: 6px;
}
`

const LogoBox = styled.div` 
/* padding-top: 183px; */
padding-top: 25vh;
width: 170px;
margin-left: auto;
margin-right: auto;
img{
    width: 100%;
}

@media (max-width: 800px) {  
  width: 81px;
  padding-top: 48px;
}
`;

const ButtonBox = styled.div`
margin-top: 58px;
display: flex;
justify-content: center;
gap: 24px;

span {
    cursor: pointer;
}

img {
    width: 277px;
}

@media (max-width: 800px) { 
    margin-top: 27px;
    img {
        width: 113px;
    }
}
`;



