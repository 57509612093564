import React from 'react'
import styled from 'styled-components';
import introStyleAtom from '../../common/introStyleAtom';
import {goHere} from '../../common/util'

const Intro6 = () => {
    const {TopTitle} = introStyleAtom();

  return (
    <Wrapper>
      <Container>
        <TopTitle style={{color:'#232323'}}>WEDID 고객센터</TopTitle>
        <MainBox>
          <BlueUnderLine>WEDID에 대해</BlueUnderLine>&nbsp;궁금한 점이나<br/> 하고싶은 말이  
          <BlueBracket>&#40;</BlueBracket>있다면?<BlueBracket>&#41;</BlueBracket>
        </MainBox>
        <Line/>
        <SubBox>
        WEDID는 카카오톡 채널을 통해 1:1 문의가 가능합니다.<br/>카카오톡 검색창에 ‘위디드'를 검색 후 문의해 주세요!
        </SubBox>
        <ChannelButt onClick={()=>{goHere('https://pf.kakao.com/_zxfBTG')}}>채널 바로가기</ChannelButt>
        <Box>
          <TagBox>05</TagBox>
        </Box>
      </Container>
    </Wrapper>
  )
}

export default Intro6

const Wrapper = styled.div`
width: 100%;
/* height: 766px;  */
height: 100vh;
display: flex;
justify-content: center;
@media (max-width: 800px) {
  height: 279px;
}
` 

const Container = styled.div`
display: flex;
width: 1580px;
padding-left: 70px;
padding-right: 70px;
height: 100%;
flex-direction: column;
@media (max-width: 800px) {
  width: 375px;
  padding: 0 25px;
}
`

const MainBox = styled.div`
width: 100%;
height: 192px;
/* margin-top: 63px; */
margin-top: 15vh;
text-align: right;
color: #232323;
text-align: right; 
font-size: 70px; 
font-weight: 700;
line-height: 96px; 
letter-spacing: -1.4px;
@media (max-width: 800px) {
  margin-top: 15px;
  height: 60px;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  margin-left: 12px;
  margin-right: 12px;
}

`

const BlueUnderLine = styled.span`
  position: relative;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -1.4px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-color: #498AFF;
    z-index: -1;
  }

@media (max-width: 800px) {
font-size: 24px;

  &:after {
    height: 7px;
  }
}
`;

const BlueBracket = styled.span`
color: #498AFF;
font-size: 75px;
font-weight: 900;
letter-spacing: -7px;
@media (max-width: 800px) {
  font-size: 28px;
  letter-spacing: -3px;

}
`

const Line = styled.div`
width: 100%;
height: 1px;
background-color: #232323;
margin-top: 36px;
margin-bottom: 36px;
@media (max-width: 800px) { 
  width: calc(100% - 24px);
  margin: 8px 12px;
}
`

const SubBox = styled.div`
width: 658px;
color: #232323; 
font-size: 24px; 
font-weight: 500;
line-height: 34px;
@media (max-width: 800px) { 
  width: 283px;
  font-size: 12px;
  line-height: 17px;
  margin-left: 12px;
  margin-right: 12px;
}
`

const ChannelButt = styled.button`
width: 253px;
border-radius: 34px;
background: #498AFF;
border: none;
margin-top: 56px;
color: #FFF; 
font-size: 24px; 
font-weight: 500;
line-height: 42px; 
padding-top: 13px;
padding-bottom: 13px;
@media (max-width: 800px) {
  margin-left: 12px;
  border-radius: 16px;
  margin-top: 25px;
  width: 124px;
  font-size: 11px;
  line-height: 11px;
  padding: 11px 0;
}
`

const Box = styled.div`
@media (min-width: 800px) { 
  display: none;
}
position: relative;
height: 100%;
`

const TagBox = styled.div`
width: 305px;
height: 61px;
background-color: #232323;
z-index: 3;
color: #FFF;
font-size: 22px;
padding-left: 31px;
padding-top: 16px;
border-radius: 12px 12px 0 0;
position: absolute;
bottom: 0;
font-weight: 600;
@media (max-width: 800px) { 
  width: 121px;
  height: 21px;
  font-size: 12px;
  padding-top: 7px;
  padding-left: 13px;
  border-radius: 5px 5px 0 0;
}
`