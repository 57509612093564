import React from 'react'
import styled from 'styled-components'

const introStyleAtom = () => {
  return { Container, Flex, Flex2, TagBox, TopTitle }
}

export default introStyleAtom

const Container = styled.div`
display: flex;
width: 1580px;
padding-left: 70px;
padding-right: 70px;
height: 100%;
@media (max-width: 800px) { 
  width: 375px;
  padding-left: 25px;
  padding-right: 25px;
}
`

const Flex = styled.div`
flex: 1;
`

const Flex2 = styled.div`
flex: 1;
`

const TagBox = styled.div`
@media (min-width: 800px) { 
  display: none;
}
width: 305px;
height: 61px;
background-color: #FFF2EE;
z-index: 3;
color: #FF8667;
font-size: 22px;
padding-left: 31px;
padding-top: 16px;
border-radius: 12px 12px 0 0;
position: absolute;
bottom: 0;
font-weight: 600;
@media (max-width: 800px) { 
  width: 121px;
  height: 21px;
  font-size: 12px;
  padding-top: 7px;
  padding-left: 13px;
  border-radius: 5px 5px 0 0;
}
`

const TopTitle = styled.div`
color: #FFF;
font-size: 32px;
font-weight: 700;
line-height: 35px;
margin-top: 34px;
margin-left: 31px;
@media (max-width: 800px) {  
  font-size: 14px;
  margin-left: 12px;
  margin-top: 6px;
}
`